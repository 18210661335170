.containerBg,
.containerBg * {
  transition: 0.3s;
}
.containerBg {
  position: fixed;
  width: 100%;
  background: var(--white);
  z-index: 5;
  box-shadow: 0px 1px 4px 1px rgb(157 154 192 / 25%);
}
.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.nav {
  margin-right: auto;
  color: var(--neutral-100);
  font-weight: 500;
}
.nav a {
  color: inherit;
  margin-left: 2.2rem;
}
.nav a.active {
  color: var(--primary-60);
}
.nav a:hover {
  color: var(--primary-60);
}
.btn {
  background: var(--primary-60);
  color: #fff;
  border: none;
  border-radius: 6px;
  padding: 10px 24px;
}
.hamburger {
  display: none;
}

@media screen and (max-width: 800px) {
  .logoWrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .logo {
    width: 90px;
    height: 100%;
  }
  .container {
    display: grid;
    gap: 2.5rem;
    justify-content: unset;
  }
  .nav {
    display: grid;
    gap: 1.3rem;
    font-size: 15px;
  }
  .nav a {
    margin-left: 0;
  }
  .btn {
    margin-bottom: 1rem;
  }
  .hamburger * {
    transition: 0.3s;
  }
  .hamburger {
    display: flex;
    flex-direction: column;
    width: 30px;
    height: 30px;
    padding: 6px 0;
    justify-content: space-between;
    background: transparent;
    border: none;
    cursor: pointer;
    position: absolute;
    right: 1rem;
  }
  .hamburger div {
    background: #1b1f27;
    height: 2px;
    width: 100%;
    border-radius: 4px;
  }
  .hamburger div:last-of-type {
    width: 50%;
    margin-left: auto;
  }
  .closeMenuBtn div:first-of-type {
    transform: rotate(45deg) translate(6px, 6px);
  }
  .closeMenuBtn div:nth-of-type(2) {
    transform: rotate(-45deg) translate(6px, -5px);
  }
  .closeMenuBtn div:last-of-type {
    display: none;
  }
}
