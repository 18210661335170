.price {
  display: grid;
  grid-template-columns: 1fr 1.5fr 0.9fr;
  align-items: center;
  border: 1px solid var(--neutral-10);
  margin-bottom: 1rem;
}
.info {
  padding: 1.3rem 1rem;
}
.info__txt1 {
  color: var(--neutral-100);
  font-weight: 600;
  font-size: 15px;
  margin-bottom: 10px;
}
.info__txt2 {
  margin-bottom: 0;
}
.benefits {
  border-left: 2px solid var(--neutral-10);
  padding: 1.3rem 1rem;
}
.benefits p {
  color: var(--neutral-60);
  font-size: 15px;
}
.benefits ul {
  color: var(--neutral-100);
  padding-left: 0;
  list-style: none;
}
.benefits li {
  line-height: 1.8;
  display: flex;
  align-items: center;
  margin-bottom: 6px;
}
.benefits li::before {
  content: "";
  background: url(../../assets/vectors/redTick.svg);
  display: block;
  width: 24px;
  height: 24px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  margin-right: 1rem;
}
.value {
  height: 100%;
  border-left: 2px solid var(--primary-70);
  padding: 4rem 1rem;
}
.value__ttl {
  color: var(--neutral-100);
  font-weight: 700;
  font-size: 24px;
}
.value__txt {
  margin: 0;
  color: var(--neutral-60);
}

@media screen and (max-width: 900px) {
  .price {
    grid-template-columns: 1fr;
    border-radius: 10px;
    box-shadow: 0px 2px 2px 0px rgb(157 154 192 / 15%);
  }
  .info {
    padding: 1.5rem 1rem;
  }
  .info__txt1 {
    font-size: 14px;
  }

  .benefits {
    border-left: 0;
    border-top: 2px solid var(--neutral-10);
  }
  .benefits p {
    font-size: 14px;
  }
  .benefits li {
    font-size: 14px;
  }
  .value {
    border-left: 0;
    border-top: 2px solid var(--primary-70);
    padding: 2rem 1rem;
  }
  .value__ttl {
    margin-bottom: 6px;
    font-size: 20px;
  }
}
