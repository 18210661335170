.blur {
  width: 100%;
  height: 100%;
  min-height: 350px;
  background-size: cover;
  background-repeat: no-repeat;
  animation: colors 8s ease-in-out infinite;
  position: relative;
  --mask: linear-gradient(
      to left,
      rgba(0, 0, 0, 1) 0,
      rgba(0, 0, 0, 1) 70%,
      rgba(0, 0, 0, 0) 100%,
      rgba(0, 0, 0, 0) 0
    )
    100% 50% / 100% 100% repeat-x;
  -webkit-mask: var(--mask);
  mask: var(--mask);
}

@keyframes colors {
  0% {
    background-image: url(../../assets/vectors/colorBlur1.svg);
    background-position: right;
  }
  30% {
    background-image: url(../../assets/vectors/colorBlur2.svg);
    background-position: right;
  }
  70% {
    background-image: url(../../assets/vectors/colorBlur3.svg);
    background-position: right;
  }
  100% {
    background-image: url(../../assets/vectors/colorBlur1.svg);
    background-position: right;
  }
}

@media screen and (max-width: 800px) {
  .blur {
    display: none;
  }
}
