.widget {
  position: fixed;
  bottom: 2rem;
  left: 2rem;
  box-shadow: 0px 0px 5px 1px rgb(60 77 108 / 15%);
  background: var(--primary-70);
  z-index: 2;
  border-radius: 50%;
  width: 44px;
  height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff !important;
  font-size: 18px;
}

@media screen and (max-width: 500px) {
  .widget {
    bottom: 1rem;
    left: 1rem;
    width: 36px;
    height: 36px;
    font-size: 16px;
  }
}
