.footer {
  padding-top: 3rem;
}
.logo {
  width: 100px;
  height: 100%;
  margin-bottom: 2rem;
}
.sec1 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid var(--neutral-10, #f9fafb);
  padding: 1rem 0;
}
.socials {
}
.socials a {
  margin-right: 1rem;
}
.copyright {
  color: var(--neutral-60);
  margin: 0;
}

@media screen and (max-width: 800px) {
  .sec1 {
    flex-direction: column;
    align-items: start;
  }
  .socials {
    margin-bottom: 1rem;
  }
  .logo {
    width: 90px;
  }
}
