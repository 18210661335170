*,
*::after,
*::before {
  box-sizing: border-box;
  /* transition: 0.3s; */
}

/* 
  ### Breakpoints ### 

  mobile-breakpoint: 800px;
*/

:root {
  --primary-color-blue: #3913d2;
  --primary-color-red: #f45858;
  --red: #f45858;
  --grey: #8a94a6;
  --darkGrey: #e5e5e5;
  --green: #6fcf97;
  --white: #ffffff;
  --red: #f45858;
  --darkBlue: #363641;
  --pending: #f29339;

  /* Grayscale */
  --neutral-0: #fcfcfd;
  --neutral-10: #f9fafb;
  --neutral-20: #f2f4f7;
  --neutral-30: #eaecf0;
  --neutral-40: #d0d5dd;
  --neutral-50: #98a2b3;
  --neutral-60: #667085;
  --neutral-70: #475467;
  --neutral-80: #344054;
  --neutral-90: #1d2939;
  --neutral-100: #101323;

  /* Primaries */
  --primary-0: #fff2f2;
  --primary-10: #fec1c1;
  --primary-20: #fda2a2;
  --primary-30: #fc8484;
  --primary-40: #fc8484;
  --primary-50: #fc6565;
  --primary-60: #fb4646;
  --primary-70: #fa2727;
  --primary-80: #fa0808;
  --primary-90: #de0505;
  --primary-100: #bf0404;
}

html body {
  margin: 0;
  color: #8a94a6;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden; /* Hide horizontal scrollbar */
  scroll-behavior: smooth;
  height: 100%;
  transition: 0.3s;
  font-size: 14px;
  font-family: "Inter", sans-serif;
  font-weight: 400;
}
body a {
  color: inherit;
  text-decoration: none !important;
}
html {
  scroll-behavior: smooth;
  overflow-x: hidden;
  transition: 0.3s;
}
/* width and height */
::-webkit-scrollbar {
  width: 5px;
  height: 0px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px transparent;
  border-radius: 5px;
}
::-webkit-scrollbar-track:hover {
  box-shadow: inset 0 0 5px grey;
  border-radius: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--primary-60);
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--primary-60);
}

/* App container */
.appContainer {
  width: 85%;
  margin-left: auto;
  margin-right: auto;
}
@media screen and (max-width: 1200px) {
  .appContainer {
    width: 90%;
  }
}
@media screen and (max-width: 800px) {
  .appContainer {
    width: 90%;
  }
  .sectionBelowSearchForm {
    margin-top: -4rem !important;
    padding-top: 10rem !important;
  }
  .sectionBelowSearchFormDashboard {
    margin-top: 0rem !important;
  }
}

/* Global button color styling */
.appBtnFill,
.appBtnOutline {
  border: 1px solid var(--primary-60);
  box-sizing: border-box;
  border-radius: 12px;
  font-size: 16px;
}

.appBtnFill {
  background: var(--primary-60);
  color: #ffffff;
}

.appBtnOutline {
  background: #ffffff;
  color: var(--primary-60);
}
button,
input,
textarea {
  font-family: "Inter", sans-serif;
  outline: none !important;
}

.cursor-pointer {
  cursor: pointer;
}

input::placeholder {
  color: rgba(193, 199, 208, 1);
}

input[type="text"],
input[type="password"] {
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  appearance: none !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #f3f3f3 inset !important;
  box-shadow: 0 0 0 30px #f3f3f3 inset !important;
}

[class*="modal-content"] {
  border: none !important;
  border-radius: 12px !important;
}

.landingWrapper {
  max-width: 1366px;
  margin: 0 auto;
}
.crmLandingWrapper {
  max-width: 1200px;
  padding: 0 4%;
  margin: 0 auto;
  font-family: "Poppins";
}

@media screen and (max-width: 1000px) {
  .crmLandingWrapper {
    padding: 2rem;
  }
}

@media screen and (max-width: 800px) {
  .crmLandingWrapper {
    padding: 1rem;
  }
}

.pageWrapper {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  max-width: 1366px;
  margin: 0 auto;
}

[disabled="true"],
input:disabled {
  opacity: 0.8;
  cursor: not-allowed;
}

input[type="radio"],
input[type="checkbox"] {
  -ms-transform: scale(1.3); /* IE 9 */
  -webkit-transform: scale(1.3); /* Chrome, Safari, Opera */
  transform: scale(1.3);
  accent-color: var(--primary-60);
}

.fallback {
  height: 100vh;
  justify-content: center;
  display: flex;
  align-items: center;
}

.pagePadding {
  padding-bottom: 100px;
}

/* Home pages */
.homeContainer {
  padding-left: 1rem;
  padding-right: 1rem;
  max-width: 1180px;
  margin: auto;
}
button, a {
  transition: 0.3s;
}
