.preloader {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  background: #f7f7f780;
  z-index: 1500;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.spinner {
  border: 10px solid #f3f3f3;
  border-top: 10px solid var(--primary-60);
  border-radius: 50%;
  width: 65px;
  height: 65px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
