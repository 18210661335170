.containerBg {
  background: #f5f8ff;
}
.container {
  text-align: center;
  position: relative;
  padding-top: 4rem;
  padding-bottom: 5rem;
}
.tag {
  color: var(--primary-60);
  font-weight: 600;
  font-size: 18px;
  margin-bottom: 1rem;
}
.ttl {
  color: var(--neutral-100);
  font-size: 36px;
  font-weight: 700;
  margin-bottom: 1rem;
  line-height: 1.3;
}
.txt {
  color: var(--neutral-60);
  font-size: 15px;
  line-height: 1.8;
  margin-bottom: 2rem;
}
.link {
  background: var(--primary-60);
  color: var(--white) !important;
  padding: 14px 2.5rem;
  font-size: 15px;
  border-radius: 6px;
}
.dot1 {
  position: absolute;
  left: 1rem;
  top: -45px;
}
.dot2 {
  position: absolute;
  right: 1rem;
  bottom: -45px;
}

@media screen and (max-width: 800px) {
  .tag {
    font-size: 14px;
    margin-bottom: 12px;
  }
  .ttl {
    font-size: 26px;
  }
  .txt {
    font-size: 14px;
    max-width: 400px;
    margin-left: auto;
    margin-right: auto;
    line-height: 1.5;
  }
  .link {
    font-size: 14px;
  }
  .dot1 {
    width: 100px;
    height: auto;
    top: -30px;
  }
  .dot2 {
    width: 100px;
    height: auto;
    bottom: -30px;
  }
}
